var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "eventListPage",
      attrs: { id: "eventListPage" },
      on: { click: _vm.closeStatus },
    },
    [
      _c("event-filter", {
        ref: "event-filter",
        attrs: {
          isShowBack: _vm.isShowRouteBack,
          isShowFilter: _vm.$route.query.type != "callCar",
        },
        on: { "back-event": _vm.itoback },
      }),
      _c("event-item-list", { ref: "eventlist" }),
      _vm.isCanCreate.show &&
      !["needApproval", "assistance", "region"].includes(
        _vm.$route.query.eventType
      ) &&
      _vm.$route.query.type != "callCar"
        ? _c("i-add-button", { on: { click: _vm.createEvent } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }