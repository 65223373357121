<template>
    <div>
        <div class="search-box">
            <div v-if="isShowBack" class="back-icon" @click="$emit('back-event')">
                <div v-if="isWxWork" style="width: fit-content;font-size: 0.16rem">返回</div>
                <i-icon v-else name="icon-fanhui" color="#13161B"></i-icon>
            </div>
            <i-search v-model="searchKey" shape="round"
                      clear-trigger="always"
                      @input="onSearch" :placeholder="tenantCode === 'novartis' ? '请输入ACC编号' : `请输入会议名称/${tenantCode === 'dsm' ? '申请书' : '会议'}编号${tenantCode === 'crossevent' ? '/预算编号': ''}`"> </i-search>
            <i-icon style="margin-left: 0.1rem" color="#13161B" :size="24" name="icon-kefu2" @click.native="openWebApp" />
        </div>
        <div id="filterSearch" class="filter-search" v-show="isShowFilter">
            <div class="filter-icon" v-if="haveMoreFilter" @click="showFilterBox = !showFilterBox">
                <i-icon color="#13161B" name="icon-shaixuan" :size="20" />
            </div>
            <keep-alive>
                <ul class="filter-option-list">
                    <li v-for="(item, index) in filterList" :ref="'eventTypeTab' + index" :key="index" @click="filterChange({ option: item, index: index })">
                        <div :class="index == selectedFilterOptionIndex ? 'filter-option-txt-selected' : 'filter-option-txt'"
                            >{{ item.Txt }}
                            <template v-if="item.Count"> ({{ item.Count || 0 }}) </template>
                        </div>
                        <div v-if="index == selectedFilterOptionIndex" class="filter-option-dot"></div>
                    </li>
                </ul>
            </keep-alive>
            <i-popup v-model="showFilterBox" :safe-area-inset-bottom="true"
                     class="filterDropDown"
                     position="top" :overlay-style="{ top: '1rem' }">
                <div class="cnt">
                    <i-form ref="form" @submit="onSubmit" scroll-to-error>
                        <iFormItem v-for="(item, index) in template" :key="index" :form-data="formData"
                                   :ref="item.controlName"
                                   :formTemplate="[template]" :model-val="formData[item.controlName]"
                                   :form-item="item"></iFormItem>
                    </i-form>
                </div>
                <div class="btnBox">
                    <div class="left" @click="doReset">重置</div>
                    <div class="right" @click="doFilter">完成</div>
                </div>
            </i-popup>
        </div>
    </div>
</template>

<script>
import debounce from "lodash/debounce";
import iFormItem from "@/components/iFormItem/iFormItem";
import {getCookie} from "tiny-cookie";
import PublicMethod from "@/utils/publicFun.js";

export default {
    name: "EventFilter",
    components: {
        iFormItem
    },
    props: {
        isShowBack: {
            default: false,
        },
        isShowFilter: {
            default: true,
        },
    },
    data() {
        return {
            filterList: [],
            selectedFilterOptionIndex: this.$smartStorage.get("currentEventTypeTab") !== null ? this.$smartStorage.get("currentEventTypeTab") : 0,
            eventStatus: this.$smartStorage.get('lastListParams')?.eventStatus || "",
            searchKey: this.$smartStorage.get('lastListParams')?.searchKey || "",
            onSearch: undefined,
            tenantCode: this.$smartStorage.get("tenant") || this.$cookie.get("tenant"),
            platform: this.$smartStorage.get("platform") || this.$cookie.get("platform"),

            showFilterBox: false,
            formData: {},
            template: [],
            isWxWork: window.navigator.userAgent.toLowerCase().indexOf('wxwork') !== -1,
            haveMoreFilter: false,   // 更多筛选
            isDsm: getCookie('tenant') === 'dsm'
        };
    },
    async created() {
      this.$root.$eventHub.$on('setFilterConfig', (config) => {
        this.haveMoreFilter = true
        const _this = this;
        // 过滤，执行配置的visibleRuleExpr，返回为false，理解为不需要展示到过滤条件中
        _this.template = config.filter(x => {
          return !((x?.visibleRuleExpr && x.visibleRuleExpr.length) && x.visibleRuleExpr.every((subItem) => eval(subItem)))
        });
        config.map((item) => {
          if (item.controlName) {
            this.$set(this.formData, item.controlName, "");
          }
          return item;
        });
      })
      await this.getStatus();
      this.onSearch = debounce(this.filterChange, 500);
      // this.filterChange({option: this.filterList[0]});
    },
    destroyed() {
        this.$root.$eventHub.$off('setFilterConfig')
    },
    mounted() {},
    methods: {
        clickLastLable() {
            setTimeout(() => {
                let existsLabIndex = this.$smartStorage.get("currentEventTypeTab");
                if (existsLabIndex !== null) {
                    this.$refs["eventTypeTab" + this.selectedFilterOptionIndex][0].click();
                } else {
                    this.$refs["eventTypeTab0"][0].click();
                }
            }, 10);
        },
        GetMiceStatus() {
              let oldTab = this.$smartStorage.get("MyEventTabs");
              if (oldTab) {
                this.filterList = oldTab;
                // this.clickLastLable();
              } else {
                  let param = {
                    tenantCode: this.tenantCode,
                    roleCode: this.$cookie.get("role_codes") || "",
                  };

                  this.$service.GetMiceStatus(param).then((res) => {
                    if (res.success) {
                      let arr = res.content || [];
                      arr.map((item) => {
                        item.Txt = item.txt;
                        item.Val = item.code;
                      });
                      arr.unshift({
                        Txt: "全部",
                        Val: "",
                      });
                      this.filterList = arr;
                      this.$smartStorage.set("MyEventTabs", arr);
                    }
                    // this.clickLastLable();
                  });
              }

        },
        async getStatus() {
            return new Promise(async (resolve) => {
                const flag = !!['msd', 'dsm'].includes(getCookie('tenant'));
                if(flag) {
                    // 针对默沙东和住友单独获取会议状态
                    this.filterList = await PublicMethod.getStatusOfEvents();
                    console.log(this.filterList);
                    resolve()
                }else {
                    let arr = [];
                    if (this.$route.query.eventType) {
                        arr = this.$smartStorage.get(this.$route.query.eventType + "EventTabs") || [];
                        if(arr.length) {
                            arr.map((item) => {
                                item.Txt = item.txt;
                                item.Val = item.code;
                            });
                            this.filterList = arr;
                        }else {
                            this.GetMiceStatus();
                        }

                        // this.clickLastLable();
                    } else {
                        this.GetMiceStatus();
                    }
                    resolve();
                }
            })

        },
        filterChange({ option, index }) {
            if (option) {
                this.eventStatus = option.Val;
            }else {
                const myIndex = this.$smartStorage.get('currentEventTypeTab');
                this.eventStatus = this.filterList[myIndex].Val || '';
            }
            if (index !== undefined) {
                this.selectedFilterOptionIndex = index;
                this.$smartStorage.set("currentEventTypeTab", index);
            }
            this.onSubmit(this.$refs.form ? this.$refs.form.getValues() : null)
        },
        openWebApp() {
            const appConf = {
                webAppAlias: "webapp-ihelper",
                path: "/?",
                params: "tenant=" + this.tenantCode + "&platform=" + this.platform,
            };
            this.$publicFun.openWebAppCate({}, appConf);
        },
        doReset() {
            this.showFilterBox = false
            this.template.map((item) => {
                if (item.controlName) {
                    this.$set(this.formData, item.controlName, "");
                }
                if (['iDatePicker', 'iCascaderRest'].includes(item.type)) {
                    this.$nextTick(() => {
                        this.$refs[item.controlName][0].$children[0].reassign('');
                    });
                }
            });
            this.onSubmit(this.formData)
        },
        doFilter() {
            this.showFilterBox = false
            this.$refs.form.submit();
        },
        onSubmit(values) {
            // 针对DSM住友做的特殊处理，搜索城市只传市过去，还不能带'市'
            if(this.isDsm && values && values.EventCity){
                values.EventCity = values.EventCity.split('/')[1].split('市')[0];
            }
            // 删除多余的非法值和sourceData后缀的值
          for (const key in values) {
            if (key == "undefined" || key.includes("SourceData")) {
              delete values[key];
            }
          }
          // 这里再加一道过滤，如果配置了useVal, 则取val传给接口
          this.template.forEach(i => {
            if(i.useVal && i.selectedValue){
              values[i.controlName] = i.selectedValue.val;
            }
          })
          if(this.$smartStorage.get('backListOn')) this.$smartStorage.remove('backListOn');
            if(this.$smartStorage.get('lastListParams')) this.$smartStorage.remove('lastListParams')
            this.$root.$eventHub.$emit("updateEventList", Object.assign({
                eventStatus: isNaN(Number(this.eventStatus)) ? JSON.parse(this.eventStatus) : this.eventStatus === 0 || this.eventStatus ? Number(this.eventStatus) : '',
                searchKey: this.searchKey,
            },{ filterData: values }));
        }
    }
};
</script>

<style lang="less" scoped>
.search-box {
    display: flex;
    align-items: center;
    width: 100vw;
    padding: 0.2rem 0.18rem;
    box-sizing: border-box;

    svg {
        //margin-left: 0.1rem;
    }
    .back-icon {
        height: 0.3rem;
        display: flex;
        align-items: center;
        margin-right: 0.1rem;
        flex-shrink: 0
    }

    /deep/ .van-search {
        width: 100%;
        padding: unset;
        background: #F5F5F5;
        border-radius: 0.04rem;

        .van-search__content {
            height: 0.3rem;
            background: #F5F5F5;
            //box-shadow: 0px 0.02rem 0.13rem 0px rgba(35, 24, 21, 0.1);
            display: flex;
            align-items: center;
            font-size: 0.14rem;

            .van-cell {
                height: 0.3rem;
                box-sizing: border-box;
                display: flex;
                align-items: center;
            }
            .van-field__control {
                height: 0.3rem;
            }
            .van-field__control::placeholder {
                color: #909399;
                -webkit-text-fill-color: #909399 !important;
            }
        }
    }

    /deep/ .van-icon-search {
        color: #c0c4cc;
    }
}

.filter-search {
    width: 100vw;
    padding: 0 0.18rem;
    box-sizing: border-box;
    display: flex;

    .filterDropDown {
        max-height: 60vh;
        position: absolute;
        width: 100%;
        top: 1rem;
        padding: 0.15rem 0.25rem;
        box-sizing: border-box;
        .cnt{
            .van-field__label{
                font-size: 0.16rem;
            }
          ::v-deep.van-field__control{
              background-color: #f5f5f5;
              padding: 0 .10rem;
              color: #13161B;

          }
            ::v-deep .van-field__control::-webkit-input-placeholder {
                color: #909399;
                font-size: 0.15rem;
                //font-weight: 600;
            }

        }
        .btnBox {
            display: flex;
            justify-content: space-between;
            .left {
                width: 1.56rem;
                height: 0.44rem;
                font-size: 0.16rem;
                font-weight: 500;
                color: #90939A;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0.01rem solid #ebedf0;
                border-radius: 0.04rem;
                box-sizing: border-box;
            }
            .right {
                width: 1.56rem;
                height: 0.44rem;
                background: var(--themeColor);
                border-radius: 0.04rem;
                font-size: 0.16rem;
                font-weight: 500;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .filter-icon {
        width: 0.3rem;
        height: 0.2rem;
        display: flex;
        align-items: center;
        padding-right: 0.1rem;
        border-right: 1px solid #e6e7e9;

        .filter-icon-dot {
            width: 0.05rem;
            height: 0.05rem;
            border-radius: 50%;
            background-color: var(--themeColor);
            display: inline-block;
            position: absolute;
            right: 0.15rem;
            top: 0;
        }
    }

    .filter-option-list {
        width: 100vw;
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
        flex-wrap: nowrap;
        //justify-content: space-between;
        min-height: 0.3rem;

        &::-webkit-scrollbar {
            display: none;
        }

        li {
            min-width: 0.3rem;
            position: relative;
            margin-right: 0.1rem;
            //color: #90939a;
            //-webkit-flex: 1 0 auto;
            //flex: 1 0 auto;
            flex-shrink: 0;
            height: 0.3rem;
            text-align: center;
            //margin-top: 0.02rem;
            padding: 0 0.1rem;

            .filter-option-txt {
                // position: absolute;
                font-size: 0.14rem;
                font-weight: 500;
                color: #90939a;
                line-height: 0.22rem;
                width: 100%;
                left: 0;
            }
            .filter-option-txt-selected {
                // position: absolute;
                font-size: 0.15rem;
                color: #13161B;
                font-weight: 800;
                line-height: 0.22rem;
                width: 100%;
                left: 0;
            }

            .filter-option-dot {
                width: 0.15rem;
                height: 0.04rem;
                background: var(--themeColor);
                border-radius: 0.01rem;
                margin: 0 auto;
                //position: absolute;
                //bottom: 0.02rem;
                //left: 45%;
            }
        }
    }
}

</style>
